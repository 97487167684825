.summary__container {
    display: grid;

    grid-template-columns: 60% 40%;
    grid-template-rows: auto auto;
}

.summary__inventory {
    grid-column: 1;
    grid-row: 1;
}

.summary__buttons {
    grid-column: 1;
    grid-row: 2;
}

.summary__details {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: row2-end;

    padding-left: 2rem;
}

@media screen and (max-width: 834px){
    .summary__container {
        display: flex;
        flex-direction: column;
    }

    .summary__inventory {
        order: 1;
    }

    .summary__buttons {
        order: 3;
    }

    .summary__details {
        order: 2;

        padding-left: 0;
    }
}



.summary__label {
    font-size: 9px;
    line-height: 450%;
    /* or 40px */

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #000000;

    opacity: 0.6;
}

.summary__property-details {
    font-size: 14px;
    line-height: 160%;
}

.summary__location {
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 10px;


    color: #000000;

    opacity: 0.6;
}

.summary__date-value {
    opacity: 0.6;
}

.summary__date-display {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 9px 0;
}

.summary__icon_container {
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0.4;

    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.summary__icon_container:hover {
    opacity: 1;

    cursor: pointer;

    background: rgba(0, 0, 0, 0.03);
}

.summary__inventory-header {
    display: flex;
    align-items: center;
    background: #FFFFFF;

    width: 100%;

    border-bottom: 2px solid #F1F1F1;

    height: 60px;

    padding: 0 30px;
}

.summary__inventory-header p {
    margin: 0;
    padding: 0;

    font-size: 14px;
    font-style: normal;
    font-weight: normal;
}

.summary__inventory-header p span {
    opacity: 0.6;
}