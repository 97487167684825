.additional-info__header {
    font-size: 24px;

    margin: 0;
    padding: 0;
}

.additional-info__dismantle-label {
    font-size: 16px;
    color: rgba(0,0,0,1) !important;
    opacity: 1 !important;
    margin-left: 5px;

    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.dismantle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.dismantle .dismantle__text {
    padding: 15px 0 0;
    margin: 10px 0 1rem 0;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
}

.special-requirements {
    font-size: 14px;
    color: rgba(0,0,0,0.6);
}

.additional-info__keys h2{
    font-size: 16px;
    line-height: 150%;

    margin-bottom: 20px;
}