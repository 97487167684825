.dismantle-textbox {
    padding: 13px 32px 19px 20px;
    min-height: 35px;
    height: auto;
    width: 100%;
    resize: none;

    font-size: 14px;
    line-height: 19px;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.dismantle-textbox:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.dismantle-textbox:focus {
    outline: none;

    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid #2F80ED;
}

@media screen and (max-width: 836px) {
    .dismantle-textbox {
        min-height: 35px;
    }
}