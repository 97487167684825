.select {
    width: 100%;

    background: #FFFFFF;
    border: thin solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 2px;

    /*fix for subpixel border calculation bug in Chrome*/
    border-width: thin !important;

    font-size: 14px;
    height: 45px;
}

.select:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);

    cursor: text !important;
}

.select--error {
    border: thin solid #EB5757;
}

.select--error:hover {
    border: thin solid #EB5757 !important;
}



.select__control {
    border: none !important;
    outline: none !important;

    height: 100%;
}

.select__indicators {
    margin-right: 13px;

    transition-duration: 150ms !important;
}

.select__control--is-focused {
    /*border: none !important;*/
    outline: none !important;

    border-radius: 2px !important;
    border-color: transparent !important;
}

.select__control--menu-is-open .select__indicator {
    transition: transform 150ms linear !important;
    transform: rotate(180deg) !important;
}

.select__indicator {
    transition: transform 150ms linear !important;
    transform: rotate(0deg) !important;
}

.select__indicator-separator {
    display: none;
}

.select__menu {
    background: #FFFFFF !important;
    border-radius: 3px !important;
    box-shadow: 0 25px 30px rgba(0, 0, 0, 0.05) !important;

    margin-bottom: 100px !important;

    height: fit-content !important;
}

.select__menu-list {
    height: fit-content !important;
    max-height: 300px !important;
}

.select__option {
    background: #FFFFFF !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    color: rgba(0,0,0,0.6) !important;
}

.select__option:hover {
        background: rgba(0, 0, 0, 0.03) !important;
        color: rgba(0,0,0,1);
}

.select__value-container {
    padding-left: 1rem !important;
}

.select__value-container svg {
    display: none;
}