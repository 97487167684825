.stepper__container {
    height: 100%;
    display: flex;
    align-items: center;

    /*this option prevents widget from jumping when button disappears but creates overlapping with button on small windows*/
    /*position: absolute;*/
    /*left: 20vw;*/
}

.stepper__steps {
    display: flex;
    flex-direction: row;

    list-style-type: none;

    margin: 0;
    padding: 0;
}

.stepper__step {
    border-radius: 50%;
    background: #2678CB;

    width: 25px;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 11px;
    color: white;

    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.1);

    cursor: default;

    transition-duration: 100ms;
    transition-timing-function: ease-out;
}

.stepper__step_disabled {
    width: 9px;
    height: 9px;
    opacity: 0.4;

    box-shadow: 0px 14px 25px rgba(0, 0, 0, 0.1);
}

.stepper__step_disabled:hover {
    box-shadow: 0px 14px 25px rgba(0, 0, 0, 0.3);

    cursor: pointer;
}

.stepper__step_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-left: 0.6rem;
}


.stepper__step_container:last-child::after {
    content: none;
}
.stepper__step_container:first-child {
    margin-left: 0;
}

.stepper__step_container::after {
    content: '- - - -';

    margin-left: 8px;
    opacity: 0.4;
    border-radius: 50%;
    color: #000000;
    vertical-align: middle;
    font-size: 12px;
}

.stepper__step_container_finished .stepper__step:hover{
    background: rgba(38, 120, 203, 0.1);

    transition: background-color;
    transition-duration: 100ms;
}

.stepper__step_container_finished .stepper__step{
    background-color: #FFFFFF;
    box-shadow: none;
    font-weight: bold;

    cursor: pointer;
}


.stepper__step_container_disabled::after {
    content: '- - - -';

    margin-left: 8px;
    border-radius: 50%;
    color: #000000;
    vertical-align: middle;
    font-size: 12px;

    opacity: 0.2 !important;
}