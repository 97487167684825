.when-selects {
    display: flex;
    flex-direction: row;

    max-width: 100%;
}

.moving-type-select {
    width: 220px;

    margin-right: 1rem;
}

.when-group {
    margin-top: 64px;
}


.from-where-selects {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    padding-top: 30px ;
}

.from-where-selects p {
    margin: 0px 0 10px;
    opacity: 0.6;
    font-size: 14px;
}

.from-where-selects__group {
    width: 40%;
}

.from-where-selects__separator {
    padding-top: 30px;
}

@media screen and (max-width: 768px) {
    .when-selects {
        flex-direction: column;
    }

    .when-group {
        margin-top: 50px;
    }

    .from-where-selects {
        flex-direction: column;
    }

    .from-where-selects__separator {
        padding: 0;
        margin-top: 9px;
        margin-bottom: 21px;
    }

    .from-where-selects__separator svg {
        transform: rotate(90deg);
    }
}

