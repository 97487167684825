::-webkit-scrollbar {
    height: 5px;
    width: 10px !important;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-track
{
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0,0,0,0.05);
}



.inventory-option {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    height: 60px;

    flex-basis: 168px;
    flex-grow: 1;
    max-width: 168px;

    opacity: 0.4;

    border-radius: 4px 4px 0 0;
}

.inventory-option--selected {
    border-bottom: 2px solid #2678CB;

    opacity: 1;
}

.inventory-option:hover {
    background: rgba(0,0,0,0.03);
    opacity: 0.8;

    cursor: pointer;
}

.inventory-option__label {
    user-select: none;
}

.inventory-option__icon {
    margin-left: 10px;
}

.inventory__tabs {
    width: 100%;

    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 836px) {
    .inventory__tabs {
        overflow-x: scroll;
    }

    .inventory-option {
        padding: 0 1rem;
    }
}

.inventory-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);

    margin-bottom: 2rem;
}
.inventory-list__bottom {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 79px;
}

.inventory-list__new-item-button {
    height: 40px;
    width: 145px;

    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;

    color: #2678CB;
    font-size: 14px;
    line-height: 150%;
}

.inventory-list__new-item-button:hover {
    background: rgba(0,0,0,0.03);
    border-radius: 2px;
    cursor: pointer;
}

.inventory-list__item {
    width: 100%;

    display: flex;
    justify-content: space-between;

    border-bottom: 2px solid #F1F1F1;;
}

.inventory-list__item:hover {
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);

    transition: box-shadow 50ms linear;
}

.inventory-list__item_name {
    padding: 0 0 0 30px;
    overflow: hidden;

    height: 63px;
}
.inventory-list__item_name p {
    font-size: 14px;
    line-height: 450%;
    margin: 0;

    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
}

.inventory-list__item_name input{
    font-size: 14px;
    line-height: 450%;
    margin: 0;

    border: none;
}

.inventory-list__item_name input:focus{
    outline: none;
}

.item-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-right: 20px;
    padding-left: 20px;
}

.item-toolbar--expanded {
    display: flex;
    justify-content: flex-end;
}

.item-toolbar p {
    font-size: 12px;
    margin: 0;
}

.item-toolbar__icon {
    width: 35px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.item-toolbar__icon:hover {
    cursor: pointer;
}

.item-toolbar .big-plus:hover {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.03);

    cursor: pointer;
}

.expanded-toolbar {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.expanded-toolbar__icon {
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.1);
}

.expanded-toolbar__icon:hover {
    border: 1px solid #000000;

    cursor: pointer;
}

.expanded-toolbar__additional-icon {
    opacity: 0.4;
}

.expanded-toolbar__additional-icon:hover {
    opacity: 1;

    border-radius: 50%;
    background: rgba(0,0,0,0.03);
}

.expanded-toolbar p {
    margin: 0 14px;

    display: flex;
    align-items: center;

    user-select: none;
}

@media screen and (max-width: 836px) {
    .inventory-list__item {
        flex-wrap: wrap;
    }

    .item-toolbar--expanded {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 100%;

        padding: 0 0 1rem;

        /*height: 63px;*/
    }
}

.count-input {
    width: 31px;
    height: 31px;
    margin: 0 2px;
    border: none;

    text-align: center;

    font-size: 12px;
}

.count-input:focus {
    outline: none;

    border: 1px solid #2678CB;
    border-radius: 2px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}