
.radio {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: -15px;
}

.radio__item {
    flex: 0 0 auto;
    margin-top: 15px;
    display: flex;
    justify-content: center;
}