
.form__text_input {
    background: #FFFFFF !important;
    /* Field / Border regular */
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 2px;
    line-height: 19px;

    height: 45px;
    width: 100%;
    padding: 1rem 1rem 1rem 19px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;


    color: rgba(0,0,0,1) !important;

    -webkit-appearance: none;
}
.form__text_input:focus {
    border: 1px solid #2F80ED !important;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05);

    color: rgba(0,0,0,0.8) !important;
    outline: none;
}

.form__text_input:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 2px;
}

.form__text_input_disabled {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;

    color: rgba(0,0,0,0.2) !important;
}

.form__text_input_error {
    border: 1px solid #EB5757 !important;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05) !important;
}

.form__label {
    font-size: 14px;
    opacity: 0.6;

    margin-bottom: 10px;
}

.form__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
