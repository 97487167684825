.date-select {
    width: 100%;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 2px;

    font-size: 14px;
    height: 45px;
}

.date-select__control {
    border: none !important;
    outline: none !important;

    height: 100%;
}

.date-select__indicators {
    margin-right: 13px;

    transition-duration: 150ms !important;
}

.date-select__control--is-focused {
    /*border: none !important;*/
    outline: none !important;

    border-radius: 2px !important;
    border-color: transparent !important;
}

.date-select__control--menu-is-open .date-select__indicator {
    transition: transform 150ms linear !important;
    transform: rotate(180deg) !important;
}

.date-select__indicator {
    transition: transform 150ms linear !important;
    transform: rotate(0deg) !important;
}

.date-select__indicator-separator {
    display: none;
}

/*.date-select__input {*/
/*    display: none !important;*/
/*}*/