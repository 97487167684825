.radio__container {
    padding: 11px 35px;

    background: rgba(0, 0, 0, 0.03);

    border-radius: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.radio__container .radio__icon {
    display: none;
}

.radio__container:hover {
    background: rgba(0, 0, 0, 0.05);

    cursor: pointer;
}


.radio__container_selected {
    background: #2678CB;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);

    color: #FFFFFF;

    padding: 11px 23px;

}

.radio__container_selected .radio__icon {
    display: inline;
    margin-right: 10px;
}


.radio__container_selected:hover {
    background: #2678CB;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);

    color: #FFFFFF;
}

.radio__icon {
    display: inline;
}

.radio__text {
    margin: 0;
    padding: 0;

    user-select: none;

    display: inline;
}