.checkbox {
    height: fit-content;
}

.checkbox * {
    margin: 0;
    padding: 0;
}

.checkbox label {
    height: fit-content;
    display: flex;
    align-items: flex-start;
}



.checkbox__label {
    display: inline;
    margin: 0 !important;
    /*margin-left: 10px !important;*/

    user-select: none;
    /*noinspection CssInvalidPropertyValue*/
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    font-size: 14px;
    color: #000000;
    opacity: 0.6;
}

.checkbox label:hover {
    cursor: pointer;
}
.checkbox label:hover .checkbox__label {
    opacity: 0.9;
}
.checkbox--checked .checkbox__label {
    opacity: 1;
}

.checkbox__container {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 24px !important;
    height: 24px !important;
    background: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.15);
    box-sizing: border-box;
    border-radius: 1px;

    flex-shrink: 0;
}

.checkbox label:hover .checkbox__container {
    border: 1px solid #2678CB;
    box-sizing: border-box;
    border-radius: 1px;
}


.checkbox--checked .checkbox__container {
    background: rgba(56, 130, 206, 0.05);
    border: 1px solid #3780CC;
}

.checkbox__container input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox__checkmark {
    display: none;
}

.checkbox--checked .checkbox__checkmark {
    display: initial;
}

.checkbox__label-container {
    padding-left: 10px;
    padding-top: 1px;
}