* {
    box-sizing: border-box;

    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Nunito' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 150% !important;

    background: #FDFEFF !important;
}

.h1 {
    font-size: 24px !important;
    margin: 0 !important;
}

.global-container {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}

.global-content {
    padding-left: calc(23.15vw - 49px);
    padding-right: calc(23.15vw - 49px);
    padding-top: 36px;
    margin: 0 0 100px;
}

.global-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
}

@media screen and (max-width: 834px){
    .global-bottom {
        position: initial;

        height: 159px;
    }

    .global-content {
        margin: 0;
        /*padding-left: 25px;*/
        /*padding-right: 25px;*/

        padding-left: calc(23.15vw - 49px);
        padding-right: calc(23.15vw - 49px);
    }

    .global-bottom_shadow {
        box-shadow: none;
    }
}

@media screen and (min-width: 834px) {
    .global-content {
        box-sizing: content-box;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
    }

    .global-bottom_shadow {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    }
}
