.top__container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    overflow: auto;
    overflow-x: hidden;
    transition: all 0.15s linear;

    background-color: #FFFFFF;
    border-radius: 2px;
    height: 65px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 3px;
}

.top__shadow {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.top__left_container {
    display: flex;
    justify-content: end;
    flex-direction: row;

    height: 100%;
    align-content: center;
}

.top__right_container {
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    font-size: 12px;
}

.top__icon_container {
    width: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.top__icon {
    padding-bottom: 1px;
}

.top__phone_icon_container {
    margin-right: 11px;
}

.top__left_text_container {
    text-align: center;
    vertical-align: middle;

    display: flex;
    align-items: center;

    font-size: 14px;

    margin-left: 14px;
}

@media screen and (max-width: 834px) {
    .large-text {
        display: none;
    }
}

@media screen and (min-width: 834px) {

    .top__container {
        padding-left: 4vw;

        padding-right: 5.4vw;
    }

    .top__left_text_container {
    }

    .top__phone_icon_container {
        margin-left: 25px;
    }
}

@media screen and (min-width: 969px) {
    .small-text {
        display: none;
    }
}

@media screen and (max-width: 969px) {
    .large-text {
        display: none;
    }
}

@media screen and (max-width: 834px) {
    .top__container {
        padding-right: 23px;
        padding-left: 18px;
    }
}


.top__text {
    overflow: visible;
    font-size: 14px;
    text-align: center;

    white-space: nowrap;
}
.top__text_grey {
    opacity: 0.6;
}

.top__phone {
    float: right;
    color: #2678CB;

    white-space: nowrap;
}


