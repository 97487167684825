.button {
    background: #2678CB;
    border-radius: 2px;
    display: flex;
    align-items: center;
    height: 50px;
    width: 200px;
}
.button:hover {
    cursor: pointer !important;

}

.button__next {
    background: #2678CB;
    border-radius: 2px;
    border: none;

    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin: 0;
    padding: 5px 0 5px 30px;

    color: #FFFFFF;
}

.button__next:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.button__next_summary:hover {
    box-shadow: 0 9px 10px rgba(0, 0, 0, 0.1);
}

.button__next:after {
    content: url("../assets/right-arrow.svg");

    margin-left: 17px;
    margin-top: 2px;
}

.button__next:focus {
    outline: none;
}

.button__disabled {
    opacity: 0.4;
}

.button__back {
    background: #FFF;
    border-radius: 2px;
    border: none;

    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin: 0;
    padding: 5px 30px 5px 0;

    color: #2F80ED;
}

.button__back:hover {
    background: rgba(38, 120, 203, 0.05);
}

.button__back:focus {
    outline: none;
}

.button__back:before {
    content: url("../assets/left-arrow.svg");

    margin-right: 17px;
    margin-top: 2px;
}

@media screen and (max-width: 834px) {
    .button {
        width: 156px;
    }
}