.bottom__left {
    order: 1;
    padding-left: 55px;

    display: flex;
    justify-content: flex-start;


}

.bottom__right {
    order: 3;
    padding-right: 55px;

    display: flex;
    justify-content: flex-end;
}

.bottom__middle {
    order: 2;

    position: absolute;
    /*left: calc(23.15vw - 49px);*/
    left: 275px;
}

@media screen and (max-width: 834px) {
    .bottom__middle {
        order: 1;

        flex: 0 0 100%;
        max-width: 100%;

        display: flex;
        justify-content: center;

        position: initial;
    }

    .bottom__left {
        order: 2;

        flex: 0 0 50%;
        max-width: 50%;

        padding-left: 22px;
    }

    .bottom__right {
        order: 3;

        flex: 0 0 50%;
        max-width: 50%;

        padding-right: 22px;
    }
}

@media screen and (min-width: 1400px) {
    .bottom__middle {
        left: calc( (100vw - 850px)/2 );
    }

}