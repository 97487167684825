.reference__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 120px;
}

.reference__container h1 {
    font-size: 24px;
    text-align: center;

    margin-bottom: 20px;
}

.reference__container p {
    font-size: 16px;
    text-align: center;

    margin-bottom: 25px;

    opacity: 0.6;
}

.reference__container small {
    color: rgba(7, 7, 7, 0.6);

    opacity: 0.6;

    font-size: 12px;

    text-align: center;

    margin-bottom: 10px;
}

.reference__number {
    text-align: center;
    padding: 17px 45px;

    color: #000000;
    opacity: 1;

    font-size: 20px!important;

    width: fit-content;

    border: thin solid rgba(0, 0, 0, 0.11);
    border-radius: 2px;

    margin: 0 auto;
}