.error__error_container {
    margin-top: 0.4rem;
    background-color: #EF0731;
    opacity: 1.1;
    border-radius: 2px;
    padding-left: 20px;
    line-height: 12px;

    display: flex;
    align-items: center;
}

.error__error_message {
    font-size: 14px;
    line-height: 12px;
    color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 5px;
}